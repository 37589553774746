import React from "react"

import Layout from "@narative/gatsby-theme-novela/src/components/Layout"
import Section from "@narative/gatsby-theme-novela/src/components/Section"
import SEO from "@narative/gatsby-theme-novela/src/components/SEO"
import Headings from "@narative/gatsby-theme-novela/src/components/Headings"

import styled from "@emotion/styled"
import CardShadow from "../components/Card"

import CustomerSupport from "@kiwicom/orbit-components/lib/icons/CustomerSupport"
import Button from "@kiwicom/orbit-components/lib/Button"
import AirConditioning from "@kiwicom/orbit-components/lib/icons/AirConditioning"
import Clock from "@kiwicom/orbit-components/lib/icons/Clock"
import ThumbUp from "@kiwicom/orbit-components/lib/icons/ThumbUp"
import Notification from "@kiwicom/orbit-components/lib/icons/Notification"
import Check from "@kiwicom/orbit-components/lib/icons/Check"
import CallOutBanner from "@kiwicom/orbit-components/lib/CallOutBanner"
import List, { ListItem } from "@kiwicom/orbit-components/lib/List"
import Text from "@kiwicom/orbit-components/lib/Text"

import imgC3 from "../assets/sensores-banner-site-c3.png"
import imgMobile from "../assets/high/sensor-c3.png"

const Flex = styled.div`
    display: flex;
    flex-wrap: ${props => {
        if (props.wrapReverse) return 'wrap-reverse'
        else if (props.noWrap) return 'nowrap'
        return 'wrap'
    }};
    justify-content: ${props => {
        if (props.justifyContent) return props.justifyContent
        if (props.justifyCenter) return 'center'
        else if (props.justifyAround) return 'space-around'
        else if (props.justifyBetween) return 'space-between'
        else if (props.justifyEnd) return 'flex-end'
        return 'flex-start'
    }};
    align-items: ${props => {
        if (props.alignItems) return props.alignItems
        else if (props.alignStretch) return 'stretch'
        else if (props.alignEnd) return 'flex-end'
        if (props.alignCenter) return 'center'
        else if (props.alignBaseline) return 'baseline'
        return 'flex-start'
    }};
    align-content: ${props => {
        if (props.alignContent) return props.content
        else if (props.contentStart) return 'flex-start'
        else if (props.contentEnd) return 'flex-end'
        else if (props.contentCenter) return 'center'
        else if (props.contentBetween) return 'space-between'
        else if (props.contentAround) return 'contentAround'
        return 'stretch'
    }};
    flex-direction: ${props => (props.column ? 'column' : 'row')};
`

export const Column = styled.div`
    width: ${props => {
        if (props.three) return '33.33%'
        if (props.four) return '25%'
        return '50%'
    }};
    padding: ${props => (props.noPadding ? 0 : '15px')};
`

function c3() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Headings.h1>C3</Headings.h1>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Headings.h2>SENSOR DE TEMPERATURA, UMIDADE E TERMOPAR TIPO K PLUG-N-PLAY</Headings.h2>
        </div>
      </Section>
      <Section>
        <Flex justifyAround>
          <img style={{ margin: "40px 0 40px 0", width: "100%" }} src={imgC3} alt="xsensor"/>
        </Flex>
      </Section>
      <Section>
        <Flex justifyAround>
        <CardShadow small delay={10}>
          <Button asComponent="button" size="large" type="secondary" bordered iconLeft={<AirConditioning />} disabled={false} width={230}>
            Resistênte à água
          </Button>
        </CardShadow>
        <CardShadow small delay={100}>
          <Button asComponent="button" size="large" type="secondary" bordered iconLeft={<ThumbUp />} disabled={false} width={230}>
            Pequeno e Compacto
          </Button>
        </CardShadow>
        <CardShadow small delay={200}>
          <Button asComponent="button" size="large" type="secondary" bordered iconLeft={<Clock />} disabled={false} width={230}>
            Mais de 2 anos bateria
          </Button>
        </CardShadow>
        <CardShadow small delay={300}>
          <Button asComponent="button" size="large" type="secondary" bordered iconLeft={<Notification />} disabled={false} width={230}>
            Alertas por Email, SMS ou Whatsapp
          </Button>
        </CardShadow>
        </Flex>  
      </Section>
      <Section style={{ marginTop: "30px" , width: "100%"}}>
        <Flex justifyCenter>
        <CallOutBanner
            title="Características"
            description="Este sensor incluí as seguintes funcionalidades e serviços:"
            dataTest="test"
            onClick={null}
            illustration={<img style={{ width: "100%"}} src={imgMobile} alt="xsensor"/>}
            actions={null}
          >
            <List size="large" type="primary" spaceAfter={null}>
              <ListItem label="Temperatura" icon={<Check />}>
                <Text size="small" element="p">Medição entre -40 °C ... +125 °C</Text>    
              </ListItem>
              <ListItem label="Termopar Tipo K" icon={<Check />}>
                <Text size="small" element="p">Medição entre -260 °C ... +1.370 °C</Text>    
              </ListItem>
              <ListItem label="Umidade Relativa" icon={<Check />}>
                <Text size="small" element="p">Medição entre 0 RH ... 100% RH</Text>    
              </ListItem>
              <ListItem label="Acesso Global" icon={<Check />}>
                <Text size="small" element="p">Funciona com rede LPWAN Global Sigfox. Conecção Wifi, 2G, 3G ou 4G não são necessárias</Text>    
              </ListItem>
              <ListItem label="Alertas Automáticos" icon={<Check />}>
                <Text size="small" element="p">Email, SMS ou Whatsapp ilimitados</Text>    
              </ListItem>
              <ListItem label="Relatórios Pré-Agendados" icon={<Check />}>
                <Text size="small" element="p">Relatórios recorrentes enviados por email em formato PDF.</Text>
              </ListItem>
              <ListItem label="Software Multi-Plataforma" icon={<Check />}>
                <Text size="small" element="p">Dashboard para monitoramento, análise e controlo acessível via Computador ou Celular.</Text>
              </ListItem>
              <ListItem label="Suporte 24/7" icon={<Check />}>
                <Text size="small" element="p">Apoio, manutenção ou substituição dispositivos imediata em caso de problema ou avaria</Text>
              </ListItem>
              <ListItem label="Calibração" icon={<Check />}>
                <Text size="small" element="p">Calibrado em Laboratório com Certificação NBR ISO/IEC</Text>
              </ListItem>
            </List>
          </CallOutBanner>
        </Flex>
      </Section>
      <Section>
        <center>
        <CardShadow small delay={100}>
          <Button href="https://xsensor.typeform.com/to/n2NhLg" asComponent="button" size="large" type="info" iconLeft={<CustomerSupport />} disabled={false} width={230}>
            Agendar Demo
          </Button>
        </CardShadow>
        </center>
      </Section>
    </Layout>
  );
}

export default c3;
